<template>
  <section class="login-page">

    <Loader v-if="loader"></Loader>

    <div class="left-log">
      <div class="vh-cent">
        <div class="log-logo">
          <a target="New" href="https://Niuteq.app"
            ><img src="@/assets/img/logo.png" alt="SITE-LOGO"
          /></a> <h2>Welcome back.</h2>
        </div>
       

        <v-form ref="form" @submit="login">
          <v-alert
            dense
            outlined
            color="error"
            class="my-5"
            dismissible
            v-if="error"
          >
              {{error}}
          </v-alert>
          <div class="form-group">
            <label for="">Email address*</label>
            <v-text-field
              type="email"
              class="form-control"
              placeholder="Email"
              required
              v-model="form.email"
              :rules="rules.emailRules"
              
            >
            </v-text-field>

          </div>
          <div class="form-group">
            <label for="" class="clearfix">
              Password*
             
            </label>
            <v-text-field
              type="password"
              class="form-control"
              placeholder="Password"
              v-model="form.password"
              required
              v-on:keyup.enter="login"
              :rules="rules.requiredRules"
            >
            </v-text-field>                  
                                  
          </div>
           <span class="pull-right forgot-dev-pass">
                 <v-btn :to="{name:'forgot-password'}"><u>Forgot ?</u></v-btn>
              </span>
          <div class="checkers">
            <v-checkbox v-model="rememberMe" label="Remember me"></v-checkbox>
          </div>
          <span class="pull-right forgot-dev-pass">
                 <v-btn :to="{name:'register'}"><u>Sign up</u></v-btn>
              </span>
          <button
            class="log-btn btn"
            type="button"
            @click="login"
          >
            Sign In
          </button>
        </v-form>
      </div>
    </div>


    <div class="right-log">
      <h1>Niuteq</h1>
      <h1>
        <span
          >Regain your Focus in daily activities </span
        >
      </h1>
    </div>


    <v-dialog
        v-model="twofactorselection"
        max-width="300px"
      >
        <v-card>

          <v-card-title class="headline purple darken-1 white--text">
            SELECT 2FA OPTION
          </v-card-title>
        
          <v-card-text>
            <v-radio-group
              v-model="twofactoroption"
              column
            >
              <v-radio
                label="Email"
                value="Email"
              ></v-radio>
              <v-radio
                label="Google Authenticator"
                value="Google Authenticator"
              ></v-radio>
            </v-radio-group>
          </v-card-text>
         
          <v-divider></v-divider>
         
          <v-card-actions>
            <button
            class="log-btn btn default"
            type="button"
            @click="save2faOption"
          >
            Save
          </button>

          <button
            class="log-btn btn error"
            type="button"
            @click="twofactorselection = false"
          >
            Close
          </button>

            
          </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog persistent width="500" v-model="twofactor">
      <v-card>

        <v-card-title class="headline purple darken-1 white--text">
          Two Factor Autentication is Needed
        </v-card-title>

        <!-- 2fa using email -->
        <v-card-text class="pa-3 2fa_email_code_email" v-if="twofactoroption=='Email'">
          <div>
            <span>
              Please check your email for Two-factor authentication..
            </span>
            <div>
              <div align="center" justify="center">
                <template>
                   <v-text-field
                      v-model="form['2fa_email_code']"
                      label="2FA"
                      type="text"
                    ></v-text-field>
                </template>
              </div>

              <div>
                <v-btn color="success" @click="twofactorSubmit()">Submit 
                   <v-progress-circular
                      v-if="loading"
                      :size="20"
                      :width="2"
                      color="white"
                      indeterminate
                    ></v-progress-circular>
                </v-btn>
  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <v-btn color="primary" @click="validateTwoFactorAuthResend()">Resend 
                   <v-progress-circular
                      v-if="loadingResend"
                      :size="20"
                      :width="2"
                      color="white"
                      indeterminate
                    ></v-progress-circular>
                </v-btn>
              </div>

              <div v-if="twofactorError" align="center" justify="center" style="margin-top: 24px">
                <v-alert type="error" transition="fade-transition">
                  {{twofactorErrorMsg}}
                </v-alert>
              </div>
              <div v-if="online" align="center" justify="center" style="margin-top: 24px">
                <v-alert type="error">
                   Please check your internet connection and try again.
                </v-alert>
              </div>

            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

  </section>
</template>

<script>
    import firebase from "firebase";
    import {mapGetters} from "vuex";
    import axios from "axios";
    import { API_BASE_URL } from "../../constants";
    import Loader from '../../components/loader';
    import './style.css';
    

    const API_URL = `${API_BASE_URL}/api/auth/`;
    var dynamicMessage = {
      email: "",
      password: "",
    };
    export default {
        name: 'App',
        components: { Loader },
        data: () => ({
            form: {
                email: "",
                password: "",
                "2fa_email_code":  ""
            },
            twofactor: false,
            twofactorError: false,
            twofactorErrorMsg: '',
            twofactorselection:false,
            twofa:false,
            error: null,
            uid: '',
            User:"",
            rememberMe:false,
            loader:true,
            loading:false,
            loadingResend:false,
            twofactoroption:"Email",
            correcttoken: false,
            errortoken: false,
            online:false,
            componentKey: 0,
            authkey: "",
            "2fa_type":0,
            investor:1,
            googleTwoValue: '',
            rules: {
                requiredRules: [v => !!v || dynamicMessage.password],
                emailRules: [
                    v => !!v || dynamicMessage.email,
                    v => /.+@.+\..+/.test(v) || "Email must be valid",
                ],
            }
        }),
        computed: {
            
            ...mapGetters({
                user: "user"
            })
            
        },
        mounted() {
          if(this.$route.query.userId)
          {
            this.loginUsingFirebaseId(this.$route.query.userId);
          }
          console.log("after mount",this.rememberMe); 
          this.checkRememberMe();
          //hide page loader  
          setTimeout(() => {
            this.loader = false;
          }, 2000)
        },
        methods: {
          login(type) {
              this.error=null;
              this.isRememberMe();

              if (this.$refs.form.validate()) {
                  this.loading = true;

                  firebase
                  .auth()
                  .signInWithEmailAndPassword(this.form.email, this.form.password)
                  .then((data) => {

                      this.uid = data.user.uid;
                      this.User = data.user;

                      //console.log("data.user = ",data.user)

                      //handle 2fa
                      //this.checkUserHasBlackListWallet();  
                      localStorage.setItem('User', JSON.stringify({"uid":this.User.uid,"email":this.User.email,"displayName":this.User.displayName}));

                      //MOCK auth al servizio API
                    let config = {
                      method: 'post',
                      maxBodyLength: Infinity,
                      url: 'http://localhost:3001/api/auth/login',
                      headers: {
                        'Content-Type': 'application/json'
                      },
                      data : JSON.stringify({
                        "username": "demo",
                        "password": "demo"
                      })
                    };

                    axios.request(config)
                        .then((response) => {
                          localStorage.setItem('token', response.data.token);
                          this.redirectTo()
                        })
                        .catch((error) => {
                          console.log(error);
                        });


                    console.log("complete successfull login")

                      //redirect to page after successfull login


                  })
                  .catch(error => {
                      if(error?.code === "auth/user-not-found" || error?.code === "auth/wrong-password") {
                        this.error = "Incorrect email or password";
                      }
                      else {
                        this.error = error.message;
                      }
                  });
              }
          },
          setcookie(){
            this.$cookies.set('uid', this.user.data.uid)
          },
          checkRememberMe(){

            if (localStorage.checkbox && localStorage.checkbox !== "") {
              this.rememberMe = true;
              this.form.email = localStorage.username;
            } else {
              this.rememberMe = false;
              this.form.email = "";
            }
          },
          isRememberMe(){
            if (this.rememberMe && this.form.email !== "") {
              localStorage.username = this.form.email;
              localStorage.checkbox = this.rememberMe;
            } else {
              localStorage.username = "";
              localStorage.checkbox = "";
            }
          },

          redirectTo(){
            this.$router.replace({name: "dashboard"})
          },
          forceRerender() {
            this.componentKey += 1;
          },
          getNewQR() {
            this.generateQrCode();
          },
          generateQrCode() {
            axios
              .get(`${API_BASE_URL}/user/2fa/${this.uid}`, {
                headers: {
                  accept: "application/json",
                },
              })
              .then((resp) => {
                console.log(resp);
                this.authkey = resp.data;
              });
          },
          onChange(v) {
            this.correcttoken = false;
            this.errortoken = false;
          },
          onComplete(v) {



          },

        },
    };
</script>


