<template>
  <div class="main-view-sec">
    <Loader v-if="loader"></Loader>

    <div class="page-headings">
      <h1 class="clearfix">
        Create Summary
      </h1>
      <v-btn color="primary" @click="openAudioDialog"><v-icon>fa-plus</v-icon></v-btn>
    </div>

    <v-dialog v-model="audioDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h6">{{ isRecordingView ? "Record Audio" : "Upload Audio" }}</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeAudioDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <!-- Tabs -->
          <v-tabs v-model="activeTab" class="justify-center">
            <v-tab style="font-size: 15px">Record</v-tab>
            <v-tab style="font-size: 15px">Upload</v-tab>
          </v-tabs>

          <v-tabs-items v-model="activeTab">
            <!-- Record Audio Tab -->
            <v-tab-item>
              <div class="pa-2">
                <v-btn v-if="!isRecording" color="primary" @click="startRecording">Start Recording</v-btn>
                <v-btn v-if="isRecording" color="error" @click="stopRecording">Stop Recording</v-btn>
              </div>
              <div v-if="audioBlob">
                <h3>Recorded Audio</h3>
                <audio :src="audioUrl" controls></audio>
              </div>
            </v-tab-item>

            <!-- Upload Audio Tab -->
            <v-tab-item>
              <div>
                <v-file-input
                    label="Select an audio file"
                    accept="audio/*"
                    v-model="uploadedFile"
                ></v-file-input>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn v-if="audioBlob" color="success" @click="uploadRecording">Upload Recording</v-btn>
          <v-btn v-else color="success" @click="uploadFile" :disabled="!uploadedFile">
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="all-table-theme new-tabl-head">
      <div class="table-responsive">
      <v-data-table
          :headers="tableHeaders"
          :items="transcriptionList.transcriptions"
          item-key="id"
          class="table table-hover"
          :loading="loader"
      >
        <template v-slot:item.play="{ item }">
          <div>
            <!-- Audio Player -->
            <audio v-if="item.wav" :src="getAudioSrc(item.wav)" controls />
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn @click="loadTranscription(item.id)" color="primary">View</v-btn>
        </template>
      </v-data-table>
    </div>
    </div>


    <br>

    <v-dialog v-model="APIdetails" max-width="650px">
      <v-card>
        <!-- Modal Header -->
        <div class="pa-3">
          <h2>Transcription Details</h2>
        </div>

        <!-- Tabs -->
        <v-tabs v-model="activeTab">
          <v-tab style="font-size: 15px">Transcription</v-tab>
          <v-tab style="font-size: 15px">Summary</v-tab>
          <v-tab style="font-size: 15px">Ask to AI</v-tab>
        </v-tabs>

        <v-tabs-items v-model="activeTab">
          <!-- Transcription Tab -->
          <v-tab-item>
            <div class="pa-3">
              <p>{{ transcriptionData.AIresponse?.text }}</p>
            </div>
          </v-tab-item>

          <!-- Summary Tab -->
          <v-tab-item>
            <div v-if="summaryData" class="pa-3">
              <div class="meeting-summary">
                <!-- Contacts Section -->
                <div class="section">
                  <h2>Contacts</h2>
                  <v-list dense>
                    <v-list-item
                        v-for="(contact, index) in summaryData.meeting_summary.contacts"
                        :key="index"
                    >
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 15px">{{ contact.name }}</v-list-item-title>
                        <v-list-item-subtitle style="font-size: 15px">{{ contact.phone }} | {{ contact.email }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                            color="primary"
                            @click="saveToContacts(contact)"
                        >
                          Save to Contacts
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </div>

                <!-- Events Section -->
                <div class="section">
                  <h2>Events</h2>
                  <v-list dense>
                    <v-list-item
                        v-for="(event, index) in summaryData.meeting_summary.events"
                        :key="index"
                    >
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 15px">{{ event.title }}</v-list-item-title>
                        <v-list-item-subtitle style="font-size: 15px">
                          {{ event.date }} | {{ event.time || (event.all_day ? "All Day" : "") }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-btn
                            color="primary"
                            @click="saveToCalendar(event)"
                        >
                          Save to Calendar
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </div>

                <!-- Notes Section -->
                <div class="section">
                  <h2>Notes</h2>
                  <v-card outlined>
                    <v-card-text>
                      <ul>
                        <li style="font-size: 15px" v-for="(note, index) in summaryData.meeting_summary.notes" :key="index">
                          {{ note }}
                        </li>
                      </ul>
                    </v-card-text>
                  </v-card>
                </div>
              </div>

              <v-btn color="primary" @click="startSummary(transcriptionData.id)">
                Regenerate Summary
              </v-btn>
            </div>
            <div v-else class="pa-3">
              <p>No summary available. Click "Summary" to generate one.</p>
            </div>
          </v-tab-item>
          <v-tab-item>

            <!-- Chat Bar -->
            <div class="pa-3">
              <!-- Chat History -->
              <div v-if="chatMessages.length" class="pa-3">
                <h3>Chat History:</h3>
                <v-list>
                  <v-list-item v-for="(message, index) in chatMessages" :key="index">
                    <v-list-item-content>
                      <p><strong>{{ message.user ? 'You:' : 'AI:' }}</strong> {{ message.text }}</p>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </div>
              <v-text-field
                  v-model="chatInput"
                  label="Type your question"
                  outlined
                  dense
                  @keyup.enter="sendChatMessage(transcriptionData.transcriptionId)"
              ></v-text-field>
            </div>


          </v-tab-item>
        </v-tabs-items>

        <v-divider></v-divider>


        <!-- Footer Actions -->
        <v-card-actions>
          <button class="log-btn btn default" type="button" @click="closeTranscriptionDetails">
            Close
          </button>
          <button v-if="!summaryData" class="log-btn btn default" type="button" @click="startSummary(transcriptionData.id)">
            Generate Summary
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import Loader from '../../components/loader';
import { bus } from '../../main';

import './index.css';

import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { SurveyCreator } from "survey-creator-knockout";

export default {
  components: { Loader },
  data() {
    return {
      creatorOptions: {
        showLogicTab: true,
        isAutoSave: true
      },
      uid: "",
      loader: false,
      transcriptionList: [],
      transcriptionData: {}, // Holds the transcription data
      APIdetails: false,
      chatInput: '', // User input in the chat bar
      chatMessages: [], // Chat history
      activeTab: 0,
      audioDialog: false, // Dialog visibility
      isRecordingView: true, // Toggle between recording and uploading
      isRecording: false, // Recording state
      mediaRecorder: null, // MediaRecorder instance
      recordedChunks: [], // Holds the recorded audio data
      audioBlob: null, // Final audio Blob
      audioUrl: null, // Blob URL for playback
      uploadedFile: null, // Uploaded file
      summaryData:{meeting_summary:{contacts:[],notes:[],calendar:[]}},
      tableHeaders: [
        { text: 'ID', align: 'start', value: 'id' },
        { text: 'Filename', value: 'name' },
        { text: 'Status', value: 'status' },
        { text: 'Riproduzione', value: 'play' },
        { text: 'Ultima Modifica', value: 'updatedAt' },
        { text: 'Actions', value: 'actions', align: 'center' },
      ],
      bearerToken: "" // Bearer token for Authorization
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  beforeMount() {
    // Get user and token from local storage
    this.User = JSON.parse(localStorage.getItem('User'));
    this.uid = this.User.uid;
    this.bearerToken = localStorage.getItem('token'); // Retrieve the token from local storage
  },
  mounted() {
    this.loadData();
  },
  methods: {
    saveToContacts(contact) {
      // Generate vCard string
      const vCard = `
BEGIN:VCARD
VERSION:3.0
FN:${contact.name}
TEL:${contact.phone}
EMAIL:${contact.email}
END:VCARD
      `.trim();

      // Trigger file download
      const blob = new Blob([vCard], { type: "text/vcard" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${contact.name}.vcf`;
      a.click();
      URL.revokeObjectURL(url);
    },
    saveToCalendar(event) {
      // Generate iCalendar (.ics) string
      const startDate = event.all_day
          ? event.date.replace(/-/g, "")
          : `${event.date.replace(/-/g, "")}T${event.time.replace(/:/g, "")}00`;
      const endDate = startDate; // For simplicity, the end date is the same as the start date

      const ics = `
BEGIN:VCALENDAR
VERSION:2.0
BEGIN:VEVENT
SUMMARY:${event.title}
DTSTART:${startDate}
DTEND:${endDate}
ALLDAYEVENT:${event.all_day ? "TRUE" : "FALSE"}
END:VEVENT
END:VCALENDAR
      `.trim();

      // Trigger file download
      const blob = new Blob([ics], { type: "text/calendar" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${event.title}.ics`;
      a.click();
      URL.revokeObjectURL(url);
    },
    getAxiosConfig() {
      return {
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${this.bearerToken}`
        }
      };
    },

    sendChatMessage(id) {
      if (!this.chatInput.trim()) return;

      // Add user message to chat history
      this.chatMessages.push({ user: true, text: this.chatInput });

      axios
          .post(`https://api.niuteq.com/v1/api/ask/summary/${id}`,{question:this.chatInput}, this.getAxiosConfig())
          .then((response) => {
            // Successfully retrieved transcription data
            this.chatMessages.push({ user: false, text: response.data });
            this.loader = false;
          })
          .catch((error) => {
            console.error('Error fetching transcription:', error);
            this.loader = false;
          });

      // Clear chat input
      this.chatInput = '';
    },
    // Configure Axios with Authorization header


    loadData() {
      this.loader = true;
      axios
          .get("https://api.niuteq.com/v1/api/transcription/list", this.getAxiosConfig())
          .then((response) => {
            // Successfully retrieved transcription data
            this.transcriptionList = response.data;
            this.loader = false;
          })
          .catch((error) => {
            console.error('Error fetching transcription:', error);
            this.loader = false;
          });
    },

    getAudioSrc(wavBuffer) {
      // Convert the buffer to a Blob URL
      const audioBlob = new Blob([new Uint8Array(wavBuffer.data)], { type: 'audio/mpeg' });

      // Generate a URL from the Blob
      return URL.createObjectURL(audioBlob);
    },

    startSummary(id){
      this.loader = true;
      axios
          .post(`https://api.niuteq.com/v1/api/summary/${id}`, this.getAxiosConfig())
          .then((response) => {
            // Successfully retrieved transcription data
            this.summaryData = response.data; // Save the summary data
            this.loader = false;
          })
          .catch((error) => {
            console.error('Error fetching transcription:', error);
            this.loader = false;
          });
    },

    loadTranscription(id) {
      this.loader = true;
      axios
          .get(`https://api.niuteq.com/v1/api/transcription/details/${id}`, this.getAxiosConfig())
          .then((response) => {
            // Successfully retrieved transcription data
            this.transcriptionData = response.data.aiDetails; // Save the transcription data
            this.loader = false;
            this.APIdetails = true; // Show the modal
            if(response.data.aiDetails){
              if(response.data.aiDetails.AIsummary){
                this.summaryData = response.data.aiDetails.AIsummary
              }
            }
          })
          .catch((error) => {
            console.error('Error fetching transcription:', error);
            this.loader = false;
          });
    },

    // Close the API modal
    closeTranscriptionDetails() {
      this.APIdetails = false;
      this.summaryData = false;
    },

    openAudioDialog() {
      this.audioDialog = true;
    },

    // Close dialog
    closeAudioDialog() {
      this.audioDialog = false;
      this.loadData()
      this.resetRecordingState();
    },

    // Reset recording state
    resetRecordingState() {
      this.isRecording = false;
      this.mediaRecorder = null;
      this.recordedChunks = [];
      this.audioBlob = null;
      this.audioUrl = null;
    },

    // Start recording
    async startRecording() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        this.mediaRecorder = new MediaRecorder(stream);

        // Handle recording data
        this.mediaRecorder.ondataavailable = (event) => {
          if (event.data.size > 0) {
            this.recordedChunks.push(event.data);
          }
        };

        // Handle recording stop
        this.mediaRecorder.onstop = () => {
          this.audioBlob = new Blob(this.recordedChunks, { type: "audio/webm" });
          this.audioUrl = URL.createObjectURL(this.audioBlob); // Create playback URL
        };

        this.mediaRecorder.start();
        this.isRecording = true;
      } catch (error) {
        console.error("Error accessing the microphone:", error);
      }
    },

    // Stop recording
    stopRecording() {
      if (this.mediaRecorder && this.mediaRecorder.state === "recording") {
        this.mediaRecorder.stop();
        this.isRecording = false;
      }
    },

    // Upload recording
    async uploadRecording() {
      if (!this.audioBlob) {
        alert("No recording available to upload.");
        return;
      }

      this.loader = true;
      const formData = new FormData();
      formData.append("audio", this.audioBlob, "recording.webm");

      try {
        const response = await axios.post("https://api.niuteq.com/v1/api/upload", formData, this.getAxiosConfig());
        alert("Recording uploaded successfully!");
        console.log(response.data);
        this.closeAudioDialog();
      } catch (error) {
        console.error("Error uploading the recording:", error);
        alert("Failed to upload recording.");
      } finally {
        this.loader = false;
      }
    },

    // Upload file
    async uploadFile() {
      if (!this.uploadedFile) {
        alert("No file selected.");
        return;
      }

      this.loader = true;
      const formData = new FormData();
      formData.append("audio", this.uploadedFile);

      try {
        const response = await axios.post("https://api.niuteq.com/v1/api/upload", formData, this.getAxiosConfig());
        alert("File uploaded successfully!");
        console.log(response.data);
        this.closeAudioDialog();
      } catch (error) {
        console.error("Error uploading the file:", error);
        alert("Failed to upload file.");
      } finally {
        this.loader = false;
      }
    }
  }
};
</script>

<style scoped>
#surveyCreator {
  height: 100vh;
  width: 100vw;
}
</style>
