<template> 
  <div>
    <div class="main-view-sec">

      <Loader v-if="loader"></Loader>

      <div class="page-headings">
        <h1 class="clearfix">
          Profile
          <span
            ><a href="javascript:void(0)" @click="redirect('dashboard')">Home</a>
            <a href="javascript:void(0)">Profile</a></span
          >
        </h1>
      </div> 
      <div class="row">
        <div class="col-sm-4">
          <div class="card-style m-b-30 theme-line">
            <div class="text-center">
              <div class="pro-media-main">
                  <a
                    href="javascript:void(0);"
                    class="fa fa-trash"
                    @click="deleteProfile = true"
                  ></a>
                  <div id="imageUpload" class="fileupload-image image-upload" v-bind:style="{ backgroundImage: 'url(' + image + ')' }">
                    <input
                      type="file"
                      title="Change Profile"
                      name="image"
                      v-on:change="changeImage"
                    />
                     <i class="fa fa-camera"></i>

                     <input v-if="selectedImage" v-model="selectedImage.name" type="hidden" name="photo" />

                  </div>
              </div>


              <h2 class="user-name">{{FirstName}} {{LastName}}</h2>
              <v-alert
                  dense
                  text
                  type="success"
                  v-if="profileImageSuccess"
                >
                  {{profileImageMessage}}
              </v-alert>



            </div>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="card-style m-b-30">
            <div class="box-head clearfix">PROFILE COMPLETION</div>
            <div class="progress-wrap progress" style="background-color:#eff3f6" data-progress-percent="20">
              <div class="progress-bar progress" :style="{'width':profileCompletionPercentage, 'background-color':'#ffc535'}"></div>
            </div>
            <h1 class="prog-counts-h1">{{profileCompletionPercentValue}} / <span>100%</span></h1>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="card-style m-b-30">
            <div class="box-head clearfix">My Notifications</div>
            <div class="card-226 mCustomScrollbar">
              <ul class="alert-list">
                <li v-if="status == 'offline'">
                  <a class="alert alert-danger" href="javascript:void(0)">
                    {{ dynamicMessage.hotspot_offline }}
                  </a>
                </li>

                <li v-if="notamonth === false">
                  <a class="alert alert-danger" href="javascript:void(0)">
                    {{ dynamicMessage.will_start_earning }}
                  </a>
                </li>
               
              </ul>
            </div>
          </div> 
        </div>
      </div>
      <div class="card-style m-b-30">
        <div class="box-head clearfix">
          Personal Details
          <button
            type="button"
            class="btn edit-btn-new pull-right"
            data-toggle="modal"
            data-target="#myModal-edit"
            data-backdrop="static" data-keyboard="false"
          >
            <i class="fa fa-pencil"></i> Edit Profile
          </button>
        </div>
        <div class="form-horizontal profile-form-cust">
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="col-sm-4 control-label">First Name</label>
                <div class="col-sm-8">
                  <p class="form-control-static">{{FirstName}}</p>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="col-sm-4 control-label">Last Name</label>
                <div class="col-sm-8">
                  <p class="form-control-static">{{LastName}}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="col-sm-4 control-label">Email</label>
                <div class="col-sm-8">
                  <p class="form-control-static">
                    {{contact.email}}
                  </p>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="col-sm-4 control-label">Phone</label>
                <div class="col-sm-8">
                  <p class="form-control-static">{{phone}}</p>
                </div>
              </div>
            </div>
          </div>


          <div class="box-head clearfix">
           Contact Address
          </div>
          
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="col-sm-4 control-label">Address 1</label>
                <div class="col-sm-8">
                  <p class="form-control-static">{{MailingStreet}}</p>
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group">
                <label class="col-sm-4 control-label">Address 2</label>
                <div class="col-sm-8">
                  <p class="form-control-static">{{MailingStreet2}}</p>
                </div>
              </div>
            </div>
            
          </div>

          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="col-sm-4 control-label">City</label>
                <div class="col-sm-8">
                  <p class="form-control-static">{{MailingCity}}</p>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="form-group">
                <label class="col-sm-4 control-label">Region </label>
                <div class="col-sm-8">
                  <p class="form-control-static">{{MailingState}}</p>
                </div>
              </div>
            </div>
          </div>
           
          <div class="row">
            <div class="col-sm-6">
              <div class="form-group">
                <label class="col-sm-4 control-label">Zip code / Postal Code</label>
                <div class="col-sm-8">
                  <p class="form-control-static">{{MailingZipCode}}</p>
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="form-group">
                <label class="col-sm-4 control-label">Country</label>
                <div class="col-sm-8">
                  <p class="form-control-static">{{MailingCountry}}</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>



    </div>

    <div class="all-pop-main" style="">
      <div
        class="modal fade"
        id="myModal-edit"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        
      >
        <div class="modal-dialog " role="document">
          <div class="modal-content">
            <div class="modal-header">
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 class="modal-title" id="myModalLabel">Edit Profile</h4>
            </div>
           
            <div class="modal-body mCustomScrollbar">

              <v-form ref="form">
                <div class="form-group">
                  <v-text-field
                  v-model="contact.FirstName"
                  label="First Name*"
                  :rules="rules.firstnameRules"
                  type="text"
                  rows="0.5"
                >
                </v-text-field>
                </div>
                <div class="form-group">
                  <v-text-field
                    v-model="contact.LastName"
                    label="Last Name*"
                    :rules="rules.lastnameRules"
                    type="text"
                    rows="0.5"
                  >
                  </v-text-field>
                </div>
                <div class="form-group">
                 <v-text-field
                    v-model="contact.email"
                    label="Email / Username*"
                    type="email"
                    disabled
                    hide-details
                  >
                  </v-text-field>
                </div>
                <div class="form-group">
                  <v-text-field
                    v-model="contact.phone"
                    label="Phone*"
                    autocomplete="off"
                    :rules="rules.phoneRules"
                    type="text"
                  >
                  </v-text-field>
                </div>
      
 
                <div class="form-group">
                   <v-textarea
                    v-model="contact.MailingStreet"
                    label="Address 1*"
                    auto-grow
                    rows="1"
                    
                    row-height="15"
                    :rules="rules.addressLine1Rules"
                    
                  >
                  </v-textarea>
                </div>
                <div class="form-group">
                   <v-textarea
                    v-model="contact.MailingStreet2"
                    label="Address 2"
                    auto-grow
                    rows="1"
                     row-height="15"
                    :rules="rules.addressLine2Rules"
                    
                  >
                  </v-textarea>
                </div>

                <div class="form-group">
                   <v-text-field
                    v-model="contact.MailingCity"
                    label="City*"
                    type="text"
                    :rules="rules.cityRules"
                  >
                  </v-text-field>
                </div>

                <div class="form-group">
                   <v-text-field
                    v-model="contact.MailingState"
                    label="Region*"
                    type="text"
                    :rules="rules.stateRules"
                  >
                  </v-text-field>
                </div>
 
                <div class="form-group">
                   <v-text-field
                    v-model="contact.MailingZipCode"
                    label="Zip Code*"
                    type="text"
                    :rules="rules.zipCodeRules"
                  >
                  </v-text-field>
                </div>

                <div class="form-group">
                   <v-text-field
                    v-model="contact.MailingCountry"
                    label="Country*"
                    type="text"
                    :rules="rules.countryRules"
                  >
                  </v-text-field>
                </div>
               
                 
              </v-form>

              
            </div>
           
           
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="button" @click="save" class="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- profile page delete model-->
    <v-dialog
        v-model="deleteProfile"
        max-width="350px">

        <v-card>
        
          <div class="pa-3">
            Are you sure want to delete profile picture?
          </div>
         
           <v-divider></v-divider>
         
            <v-card-actions>
              <button
              class="log-btn btn default"
              type="button"
              @click="deleteImage"
            >
              Delete
            </button>

            <button
              class="log-btn btn error"
              type="button"
              @click="deleteProfile = false"
            >
              Cancel
            </button>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- user information updated success message model-->
    <v-dialog
        v-model="userInformationUpdatedSuccess"
        max-width="350px" style="z-index:99999">

        <v-card>
        
          <div class="pa-3">
            <h3 class="text-center">
             User information updated successfully.
            </h3>
          </div>
         
           <v-divider></v-divider>
         
            <v-card-actions>
            

            <button
              class="log-btn btn success"
              type="button"
              @click="userInformationUpdatedSuccess = false"
            >
              Okay
            </button>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <!-- profile page delete model end-->


  </div>
</template>
<script>

import firebase from "firebase";
import axios from "axios";
import { mapGetters } from "vuex";
import VueCookies from "vue-cookies";
import Loader from '../../components/loader';
import "./index.css";
import { bus } from '../../main';

export default {
  name: "App",
  components:{ Loader },
  data() {
    return {
      deleteProfile:false,
      userInformationUpdatedSuccess: false,
      filepath: '',
      signed: '',
      sucessAlert: false,
      profileImageSuccess:false,
      profileImageMessage:'',
      uid: this.$cookies.get("uid"),
      info: {},
      FirstName: '',
      LastName: '',
      phone: '',
      MailingStreet:"",
      MailingStreet2:"",
      MailingCity:"",
      MailingState:"",
      MailingZipCode:"",
      MailingCountry:"",
      contact: {
        name: '',
        email: '',
        FirstName: '',
        LastName: '',


        MailingStreet:"",
        MailingStreet2:"",
        MailingCity:"",
        MailingState:"",
        MailingZipCode:"",
        MailingCountry:""
      },
      status: "not available",
      notamonth: true,
        dynamicMessage: {
        vaild_range: "",
        header: "",
        leased_hotspot_no_data: "",
        dashboard_loading: "",
        experiencing_diffculties: "",
        hotspot_offline: "",
        will_start_earning: "",
        start_end_date_validation: "",
        valid_data_range: "",
      },
        profile_image_uploaded: true,

      investor: 0,


      profileCompletionPercentValue: 0,
      profileCompletionPercentage: "0%",
      allowableTypes: ['jpg', 'jpeg', 'png'],
      maximumSize: 5000000,//5MB
      selectedImage: null,
      image: null,
      rules: { 
        requiredRules: [(v) => !!v || "This field is required"],
        firstnameRules:[
            v => !!v || 'First Name is required',
            v => !!/^[a-z]+$/i.test(v) || 'First Name must be valid',
        ],
        lastnameRules:[
            v => !!v || 'Last Name is required',
            v => !!/^[a-z]+$/i.test(v) || 'Last Name must be valid',
        ],
        emailRules: [
            v => !!v || 'E-mail is required',
            v => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v) || 'E-mail must be valid',
        ],
        phoneRules: [
            v => !!v || 'Phone is required',
            v => !!/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/im.test(v) || 'Phone must be valid',
        ],
        zipCodeRules:[
            v => !!v || 'Zip is required',
            v => !!/^(\d{5})?$/.test(v) || 'Zip must be valid',
        ],
        countryRules:[
            v => !!v || 'Country is required',
            v => !!/^[a-z\s]+$/i.test(v) || 'Country must be valid',
        ],
        stateRules:[
            v => !!v || 'State is required',
            v => !!/^[a-z\s]+$/i.test(v) || 'State must be valid',
        ],
        cityRules:[
            v => !!v || 'City is required',
            v => !!/^[a-z\s]+$/i.test(v) || 'City must be valid',
        ],
        addressLine1Rules:[
            v => !!v || 'Adddress 1 is required',
            v => !!/^.{0,1000}$/.test(v) || 'Adddress 1 should be less than 1000 character',
        ],
        addressLine2Rules:[
            v => !!/^.{0,1000}$/.test(v) || 'Adddress 2 should be less than 1000 character',
        ]
      },




      form: {
          password: "",
          "2fa_email_code":  ""
      },
      twofactor: false,
      twofactorError: false,
      twofactorErrorMsg: '',
      twofactorSuccessMsg: null,
      twofactorselection:false,
      twofa:false,
      twofactoroption:"",
      correcttoken: false,
      errortoken: false,
      online:false,
      componentKey: 0,
      authkey: "",
      "2fa_type":0,
      googleTwoValue: '',
      loading:false,
      loader:false
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },

  beforeMount() {

    
    //get user from local storage
    this.User = JSON.parse(localStorage.getItem('User'));

    console.log("this.User = ",this.User)

    this.contact.name = this.User.displayName,
    this.contact.email = this.User.email;

    this.uid = this.User.uid;
  },
   mounted() {
     //hide page loader  

   },
   created (){

    //listen to sidebar leased hotspot event

  },
  methods: {
    redirect(link) { //custom redirect method
      if(this.investor == 1){
          this.$router.push('investor');
      }else{
          this.$router.push('dashboard');
      }
    },
    populateField(arr) {
      //personal info
      try {
        this.contact.FirstName = arr.FirstName;
        this.FirstName = arr.FirstName;

        this.contact.LastName = arr.LastName;
        this.LastName = arr.LastName;

        this.contact.phone = arr.Mobile;
        this.phone = arr.Mobile;

      } catch (err) {
        console.log("empty value");
      }


      //contact address
      try {

        this.contact.MailingStreet = arr.MailingStreet;
        this.MailingStreet = arr.MailingStreet;

        this.contact.MailingStreet2 = arr.MailingStreet2;
        this.MailingStreet2 = arr.MailingStreet2;

        this.contact.MailingCity = arr.MailingCity;
        this.MailingCity = arr.MailingCity;

        this.contact.MailingState = arr.MailingState;
        this.MailingState = arr.MailingState;

        this.contact.MailingZipCode = arr.MailingZipCode;
        this.MailingZipCode = arr.MailingZipCode;

        this.contact.MailingCountry = arr.MailingCountry;
        this.MailingCountry = arr.MailingCountry;


      } catch (err) {
        console.log("empty value");
      }

    },


  },
};
</script>
