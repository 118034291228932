export const API_BASE_URL = 'https://api.niuteq.com';

export const FIRE_BASE =  {
    apiKey: "AIzaSyCKhLlvOD2kp343Z47r2XrII7kEbjgQTSk",
    authDomain: "niuteq-919af.firebaseapp.com",
    projectId: "niuteq-919af",
    storageBucket: "niuteq-919af.appspot.com",
    messagingSenderId: "499338748803",
    appId: "1:499338748803:web:3fa5b2637603c0b56b0cb6",
    measurementId: "G-VRZP24TFSX"
}