import Vue from 'vue'
import Router from 'vue-router'
import ForgotPassword from './pages/forgot-password';
import DashboardLayout from './layout/dashboard';
import Dashboard from './pages/dashboard';
import AuthLayout from './layout/auth';
import Login from './pages/login';
import Register from './pages/register';
import Profile from './pages/profile';
import ChangePassword from './pages/change-password';


Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: 'dashboard',
            component: DashboardLayout,
            children: [
                {
                    path: '/',
                    name: 'dashboard',
                    component: Dashboard,
                    meta: {
                      requiresAuth: true
                    }
                },
                {
                    path: 'dashboard',
                    name: 'dashboard',
                    component: Dashboard,
                    meta: {
                      requiresAuth: true
                    }
                },
                {
                    path: 'profile',
                    name: 'profile',
                    component: Profile,
                    meta: {
                      requiresAuth: true
                    }
                }
            ]
        },
        {
            path: '/',
            redirect: 'login',
            component: AuthLayout,
            children: [
                {
                    path: '/login',
                    name: 'login',
                    component: Login
                },
                {
                    path: '/forgot-password',
                    name: 'forgot-password',
                    component: ForgotPassword
                },
                {
                    path: '/change-password',
                    name: 'change-password',
                    component: ChangePassword,
                    meta: {
                      requiresAuth: true
                    }
                },
                {
                  path: '/register',
                  name: 'register',
                  component: Register
              },
            ]
        }]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in

    let User = JSON.parse(localStorage.getItem('User'));
    // if not, redirect to login page.
    if (!User) {
      next({ name: 'login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

// export router as default
export default router;