import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import firebase from 'firebase/app'
import store from "./store";
import './assets/app.scss'
import VueSignature from "vue-signature-pad";
import VueApexCharts from 'vue-apexcharts'
import VueCookies from 'vue-cookies';
import { FIRE_BASE } from './constants';
import VueJsonToCsv from 'vue-json-to-csv'
import CodeInput from "vue-verification-code-input";
import VueQrcode from '@chenfengyuan/vue-qrcode';
import VueAutosuggest from "vue-autosuggest";
import FlowyPlugin from "@hipsjs/flowy-vue";
import "@hipsjs/flowy-vue/dist/lib/flowy-vue.css";

//created to communicate bet components
export const bus = new Vue();

Vue.use(VueCookies);
Vue.use(VueApexCharts);
Vue.use(VueSignature);
Vue.use(VueAutosuggest);
Vue.use(FlowyPlugin);

Vue.component('vue-json-to-csv', VueJsonToCsv);
Vue.component('apexchart', VueApexCharts)
Vue.component("vue-qrcode", VueQrcode);
Vue.component("CodeInput", CodeInput);

Vue.config.productionTip = false

const configOptions = {
  apiKey: FIRE_BASE.apiKey,
  authDomain: FIRE_BASE.authDomain,
  databaseURL: FIRE_BASE.databaseURL,
  projectId: FIRE_BASE.projectId,
  storageBucket: FIRE_BASE.storageBucket,
  messagingSenderId: FIRE_BASE.messagingSenderId,
  appId: FIRE_BASE.appId
};

firebase.initializeApp(configOptions);

firebase.auth().onAuthStateChanged(user => {
  store.dispatch("fetchUser", user);
  VueCookies.set("uid", user.uid, "7d");
});

new Vue({
  router,
  store,
  vuetify,
  data:{ //only place where data is not a function
    isLoading : false
},
mounted: function () {
  window.test=this;
  
  document.body.onclick= function(e){
    e=window.event? event.srcElement: e.target;
    
    if(e.className && (e.className.indexOf('v-list-item__title')!=-1 ||e.className.indexOf('v-list-item primary--text v-list-item--active v-list-item--link theme--light')!=-1) )
    {
      window.test.$root.isLoading = true;
    setTimeout(()=>{
      window.test.$root.isLoading = false;
    },1500);
    }
 }
},
  render: h => h(App)
}).$mount('#app')
