<template>
  <div>
    <div class="left-header">
      
      <div class="logo">
       <a href="javascript:void(0)" @click="redirect('dashboard')">
          <img src="../../../assets/img/logo-white.png" alt="site-logo"/> 
          <span>Note taking</span>
        </a> 
      </div>
   
      <div class="slide-nav-1 mCustomScrollbar">
        <ul class="clearfix forgot-dev-pass">
          
          <li class="open-lefter"  >
            <a href="javascript:void(0)">
              <i class="fas fa-arrow-right" data-toggle="tooltip" data-placement="bottom" title="Expand"></i> <span>Close Menu</span>
            </a>
          </li> 

          <li v-bind:class="{ 'activee': currRouteName =='dashboard' }">
            <a href="javascript:void(0)" @click="redirect('dashboard')">
              <i class="fas fa-chart-bar" data-toggle="tooltip" data-placement="bottom" title="Dashboard"></i>
             <span>Dashboard</span>
            </a>
          </li>

          <li v-bind:class="{ 'activee': currRouteName =='profile' }">
          <a href="javascript:void(0)" @click="redirect('profile')">
            <i class="fas fa-user" data-toggle="tooltip" data-placement="bottom" title="Profile"></i>
            <span>Profile</span>
          </a>
          </li>

        </ul>
      </div>
   
    </div>


  </div>
</template>

<script>

    import axios from "axios";

    import { bus } from '../../../main';
    import './style.css';

    export default {
        name: 'App',
        data() {
          return {
            uid: "",
            hots: [],
            hotspotAll:[],
            activeHotspot:'',
            currRouteName:'dashboard',
            isInvestor: 0,
            page: 1,
            totalLength: 0
          }
        },
        computed: {
          currentRouteName() {
              return this.$route.name;
          }
        },
        watch:{
          $route (to, from){
              this.headRoutClickHandler(to.name);
           }
        },
        mounted() {
          this.headRoutClickHandler(this.currentRouteName);

          //this part of code is added bcz to work external JS files (jquery,custom)
          if (localStorage.getItem('reloaded')) {
              // The page was just reloaded. Clear the value from local storage
              // so that it will reload the next time this page is visited.
              localStorage.removeItem('reloaded');
          } else {
              // Set a flag so that we know not to reload the page twice.
              localStorage.setItem('reloaded', '1');
              location.reload();
          }
        },
        beforeMount() { 
          //get user from local storage
          this.User = JSON.parse(localStorage.getItem('User'));
          this.uid = this.User.uid;

          //this.getHotspotName();
          this.getUserInfo();
        },
        methods: {
          getUserInfo(){

          },
          redirect(link) { //custom redirect method
              this.$router.push(link);
              console.log("link = ",link)
          },

          formatHotspotName(name){
            let newName = name.replace(/-/g, " ").toLowerCase().replace(/\b[a-z]/g, function(letter) {
                return letter.toUpperCase();
            });

            return newName;
          },
          formatHotspotType(type) {
            if(type === "N/A") {
              return "4G";
            } else {
              return type;
            }
          },
          changeHotspot(item){
              console.log("hotspot click in sidebar = ",item.name)
              this.activeHotspot = item.name;

              //for set hotspot data
              localStorage.setItem('activeHotspot',JSON.stringify({'name':item.name,'address':item.address,'status':item.status,'total':item.total,'installDate':item.installDate,'timestamp_added':item.timestamp_added,'elegibility':item.elegibility,'lng':item.lng,'lat':item.lat}));

              //listen this evenet in dashboard page and other pages where we need current hotspot name
              bus.$emit('changeHotspot', item);

              //reset leased hotspot section (remove class with default)
              document.querySelector("body").setAttribute("class","landscape")
          },
          headRoutClickHandler(name){
            
            this.currRouteName = name;


            /*//enable leased hotspot only for dashboard hotspot page
            if(name == "dashboard" || name == "hotspot"){
              document.getElementById("LeasedHotspots").setAttribute("class","links-ab red-display")
            }else{
              //reset leased hotspot section (remove class with default)
              document.querySelector("body").setAttribute("class","landscape");
              
              document.getElementById("LeasedHotspots").setAttribute("class","links-ab red-display disabled")
            }*/
          },
          handelInput(page) {
            console.log('Random Number Clicked');
            //this.getHotspotName(page);
          }
        },
    };
</script>



