<template>
  <section class="login-page">
    <div class="left-log">
      <div class="vh-cent">
        <div class="log-logo">
          <a target="New" href="https://Niuteq.app"
            ><img src="@/assets/img/logo.png" alt="SITE-LOGO"
          /></a>
        </div>
        <h2>Please enter your email </h2>
        
        <h3 class="forgot-dev-pass">
          Want go back?
          <v-btn class="ml-2 white--text" outlined :to="{ name: 'login' }"
            >Login</v-btn
          >
        </h3>
        
        <v-form ref="form" @submit="login">
          <div class="form-group">
            <label>Email address</label>
            <v-text-field
              v-model="form.email"
              label="Email*"
              type="email"
              class="form-control"
              required
              :rules="rules.emailRules"
            >
            </v-text-field>
          </div>
          <button class="log-btn btn" type="button" @click="login(form.email)">
            Send Request
          </button>

          <v-alert
            dense
            outlined
            color="error"
            class="my-5"
            dismissible
            v-if="error"
          >
            {{ error }}
          </v-alert>

          <v-alert
            dense
            outlined
            color="success"
            class="my-5"
            dismissible
            v-if="success"
          >
            {{ success }}
          </v-alert>

        </v-form>

      </div>
    </div>
    <div class="right-log">
      <h1>Niuteq</h1>
      <h1>
        <span
          >Regain your Focus in daily activities </span
        >
      </h1>
    </div>
  </section>
</template>

<script>
import firebase from "firebase";
import axios from "axios";
import { API_BASE_URL } from "../../constants";

var dynamicMessage = {
  email: "",
};
export default {
  name: "App",
  data: () => ({
    form: {
      email: "",
    },
    error: null,
    success: null,
    rules: {
      requiredRules: [(v) => !!v || "This field is required"],
      emailRules: [
          v => !!v || dynamicMessage.email,
          v => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v) || 'E-mail must be valid',
      ],
    },
  }),
  mounted() {
    this.getDynamicMessages();
  },
  methods: {
    login(email) {
      this.error = null;
      this.success = null;
      if (this.$refs.form.validate()) {
        console.log(email);
        
        firebase.auth().sendPasswordResetEmail(email)
        .then((data) => {
          
          this.success = "Reset password link sent to your mail, please check your mail.";

          //hide msg after 4 sec
          setTimeout(()=>{
            
            this.success = null;
            //redirect when success          
            this.$router.push({ name: "login" });
          },6000);
          

        })
        .catch(error => {
            this.error = error.message;
        });
      }
    },
    getDynamicMessages() {
      axios
        .get(`${API_BASE_URL}/getMessageConfiguration/forgot`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
            dynamicMessage = resp.data;
          } catch (err) {
            this.error = true;
          }
        });
    },
  },
};
</script>